<template>
  <div>
      <NavComp/>
      <div class="container">
            <b-tabs type="is-boxed" expanded>
                <!-- Weather -->
                <b-tab-item :label="`${$t('weather.heading.weather')}`" icon='cloud' icon-pack="fas">
                    <div v-if="weatherFunctionality">
                        <div class="card half-card mid has-text-centered">
                                <!--Status:
                                <p v-if="error">{{error}}</p>
                                <p v-else>User granted permission</p> -->
                                <h4>{{ $t("weather.weather.location") }}</h4>
                                <p><!--{{ $t("weather.weather.loc_name") }}: -->
                                    <span>{{weatherData.name}}</span>, 
                                    <span>{{weatherData.region}}</span>,
                                    <span>{{weatherData.country}}</span>
                                </p>
                                <p>{{currentPosition}}</p>
                                <div class="btm"></div>
                                <div>
                                    <b-tooltip 
                                                label="Save this location to always get the weather for it"
                                                :delay="700"
                                                position="is-bottom"
                                                type="is-light"
                                    >
                                        <button class="button is-info is-light is-rounded" @click="saveHomelocation">
                                            <span class="icon">
                                                <i class="fas fa-home"></i>
                                            </span>
                                            <span>
                                                Save as homelocation
                                            </span>
                                        </button>
                                    </b-tooltip>
                                </div>
                                <div class="btm"></div>
                        </div>
                        <div class="card half-card mid has-text-centered">
                            <h4 class="">{{$t("weather.weather.current_data")}}</h4>
                                <div v-if="weatherData" class="has-text-centered">
                                    <div class="has-text-centered">
                                        <p>{{$t("weather.weather.temp")}}: {{weatherData.temp}} °C</p>
                                        <p>{{$t("weather.weather.wind_speed")}}: {{weatherData.wind_kph}} km/h</p>
                                        <p>{{$t("weather.weather.wind_dir")}}: {{weatherData.wind_dir}}</p>
                                        <p>{{$t("weather.weather.pressure")}}: {{weatherData.pressure}} hPa</p>
                                        <p>{{$t("weather.weather.precip")}}: {{weatherData.precip}} mm</p>
                                        <p>{{$t("weather.weather.humidity")}}: {{weatherData.humidity}}%</p>
                                        <!--<h5>Cloud: {{weatherData.cloud}}</h5>
                                        <h5>Gefühlte Temperatur: {{weatherData.feelslike}}</h5>-->
                                        
                                        <h4>{{$t("weather.weather.condition")}}</h4>
                                        <div v-if="weatherData.condition">
                                            <p>Condition: {{weatherData.condition[0]}}</p>
                                            <p>Bild: {{weatherData.condition[1]}}</p>
                                        </div>
                                    </div>
                                    <div class="btm"></div>
                                </div>
                                <!--
                                    function showPosition(position) {
                                        var latlon = position.coords.latitude + "," + position.coords.longitude;

                                        var img_url = "https://maps.googleapis.com/maps/api/staticmap?center=
                                        "+latlon+"&zoom=14&size=400x300&sensor=false&key=YOUR_KEY";

                                        document.getElementById("mapholder").innerHTML = "<img src='"+img_url+"'>";
                                        }
                                -->
                                <!--
                                watch permission:
                                <p>{{watchPermission}}</p>
                                -->
                                <div v-if="altitude">
                                    <p>{{altitude}}</p>
                                    <p>{{altitudeAccuracy}}</p>
                                </div>
                        </div>
                        <div class="card half-card has-text-centered mid">
                            <h4>{{$t("weather.weather.air")}}</h4>
                            <p>CO2: {{weatherData.co}} km/h</p>
                            <p>NO2: {{weatherData.no2}}</p>
                            <p>O3: {{weatherData.o3}}</p>
                            <p>SO2: {{weatherData.so2}}</p>
                            <p>PM2_5: {{weatherData.pm2_5}}</p>
                            <p>PM10: {{weatherData.pm10}}</p>
                            <div class="btm"></div>
                        </div>                        
                        <div class="has-text-centered bottom-func">
                            <!-- Permenent Geolocation watch-->
                            <!-- 
                            <b-switch
                                :rounded="geobutton_rounded"
                                :outlined="geobutton_outline"
                                :size="geobutton_size"
                                :type="geobutton_type"
                                :left-label="geobutton_leftlabel"
                                :passive-type="geobutton_passivetype"
                                :disabled="disableWatchPermission"
                                v-model="watchPermission"
                            >{{ $t("weather.geolocation.active") }}
                            </b-switch> -->
                            <div class="has-text-centered mt-6">
                                <button class="button is-danger is-rounded" :class="buttonloading" @click="toggleWeatherFunc">
                                    <span>
                                        Disable Weather
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-else class="has-text-centered">
                        <h2>Enable Weather functionality</h2>
                        <button class="button is-success is-rounded" :class="buttonloading" @click="toggleWeatherFunc">
                            <span>
                                Enable Weather
                            </span>
                        </button>
                    </div>
                </b-tab-item>
                <!-- Home Location -->
                <b-tab-item :label="`${$t('weather.heading.home')}`" icon="home" icon-pack="fas" :visible="weatherFunctionality">
                    <div class="card half-card mid has-text-centered">
                        <h4 class="">{{ $t("weather.home.heading") }}</h4>
                        <div v-if="weatherData" class="has-text-centered">
                            <div class="has-text-centered">
                                <div><p>{{$t("weather.home.home_pos")}}:  <i class="fas fa-map-pin"></i>   
                                    <span>{{weatherData.name}}</span>, 
                                    <span>{{weatherData.region}}</span>,
                                    <span>{{weatherData.country}}</span>
                                    <span>{{ homeLocation.lat }}° Lat, {{ homeLocation.lon }}° Lon</span>
                                </p></div>

                                <p>{{$t("weather.weather.temp")}}: <i class="fas fa-thermometer-half"></i> {{weatherData.temp}} °C</p>
                                <p>{{$t("weather.weather.wind_speed")}}: <i class="fas fa-wind"></i> {{weatherData.wind_kph}} km/h</p>
                                <p>{{$t("weather.weather.wind_dir")}}: <i class="fas fa-location-arrow"></i>{{weatherData.wind_dir}}</p>
                                <!--<p>{{$t("weather.weather.pressure")}}: <i class="fas fa-weight-hanging"></i>{{weatherData.pressure}} hPa</p>-->
                                <p>{{$t("weather.weather.precip")}}: <i class="fas fa-tint"></i>{{weatherData.precip}} mm</p>
                                <p>{{$t("weather.weather.humidity")}}: {{weatherData.humidity}}%</p>
                                <!--<p>Cloud: <i class="fas fa-cloud"></i>{{weatherData.cloud}}</p>
                                <p>Gefühlte Temperatur: <i class="fas fa-thermometer"></i>{{weatherData.feelslike}}</p>-->
                                        
                                <h4>{{ $t("weather.home.condition") }}</h4>
                                <div v-if="weatherData.condition">
                                    <p>Condition: {{weatherData.condition[0]}}</p>
                                    <p>Bild: {{weatherData.condition[1]}}</p>
                                </div>

                                <h4>{{ $t("weather.home.air") }}:<i class="fas fa-fan"></i></h4>
                                <p>CO2: {{weatherData.co}} km/h</p>
                                <p>NO2: {{weatherData.no2}}</p>
                                <p>O3: {{weatherData.o3}}</p>
                                <p>SO2: {{weatherData.so2}}</p>
                                <p>PM2_5: {{weatherData.pm2_5}}</p>
                                <p>PM10: {{weatherData.pm10}}</p>
                            </div>
                            <div class="btm"></div>
                        </div>
                    </div>
                    <div class="card has-text-centered half-card mid">
                        <!-- Lunar for Home -->
                        <div class="text">
                            <h4>{{ $t("weather.home.lunar") }}</h4>
                            <div class="block">
                                <!-- sun icon -->
                                <div v-if="homeAstroData[0]"  class="has-text-right">
                                    <img src="../assets/sun_moon_icons/sun_rise_icon.svg" alt="sun-rise" class="icn">
                                </div>
                                <p class="has-text-left">{{ $t("weather.home.srise") }}: <span class="bolder" v-if="homeAstroData[0]">{{homeAstroData[0].sunrise}}</span><span v-else>loading...</span></p>
                            </div>
                            <div class="block">
                                <!-- sun icon -->
                                <span v-if="homeAstroData[0]"  class="has-text-right">
                                    <img src="../assets/sun_moon_icons/sun_set_icon.svg" alt="sun-set" class="icn">
                                </span>
                                <p class="has-text-left">{{ $t("weather.home.sset") }}: <span class="bolder" v-if="homeAstroData[0]">{{homeAstroData[0].sunset}}</span><span v-else>loading...</span></p>
                            </div>
                            <div class="block">
                                <!-- moon icon -->
                                <span v-if="homeAstroData[0]"  class="has-text-right">
                                    <img src="../assets/sun_moon_icons/moon_rise_icon.svg" alt="moon-rise" class="icn">
                                </span>
                                <p class="has-text-left">{{ $t("weather.home.mrise") }}: <span class="bolder" v-if="homeAstroData[0]">{{homeAstroData[0].moonrise}}</span><span v-else>loading...</span></p>
                            </div>
                            <div class="block">
                                <!-- moon icon -->
                                <span v-if="homeAstroData[0]" class="has-text-right">
                                    <img src="../assets/sun_moon_icons/moon_set_icon.svg" alt="moon-set" class="icn">
                                </span>
                                <p class="has-text-left">{{ $t("weather.home.mset") }}: <span class="bolder" v-if="homeAstroData[0]">{{homeAstroData[0].moonset}}</span><span v-else>loading...</span></p>
                            </div>
                            <div class="block">
                                <span v-if="homeAstroData[0] && homeAstroData[0].moon_phase == 'Full Moon'" class="has-text-right">
                                        <img src="../assets/lunar_icons/full-moon.svg" alt="full-moon" class="lunar">
                                    </span>
                                    <span v-if="homeAstroData[0] && homeAstroData[0].moon_phase == 'Waxing Gibbous'" class="has-text-right">
                                        <img src="../assets/lunar_icons/waxing-gibbous.svg" alt="waxing gibbous" class="lunar">
                                    </span>
                                    <span v-if="homeAstroData[0] && homeAstroData[0].moon_phase == 'First Quarter'" class="has-text-right">
                                        <img src="../assets/lunar_icons/first-quarter.svg" alt="first-quater" class="lunar-quarter">
                                    </span>    
                                    <span v-if="homeAstroData[0] && homeAstroData[0].moon_phase == 'Waxing Crescent'" class="has-text-right">
                                        <img src="../assets/lunar_icons/waxing-crescent.svg" alt="waxing-crescent" class="lunar">
                                    </span>
                                    <span v-if="homeAstroData[0] && homeAstroData[0].moon_phase == 'New Moon'" class="has-text-right">
                                        <img src="../assets/lunar_icons/new-moon.svg" alt="new-moon" class="lunar">
                                    </span>
                                    <span v-if="homeAstroData[0] && homeAstroData[0].moon_phase == 'Waning Crescent'" class="has-text-right">
                                        <img src="../assets/lunar_icons/waning-crescent.svg" alt="waning-crescent" class="lunar">
                                    </span>
                                    <span v-if="homeAstroData[0] && homeAstroData[0].moon_phase == 'Third Quarter'" class="has-text-right">
                                        <img src="../assets/lunar_icons/third-quarter.svg" alt="third-quarter" class="lunar-quarter">
                                    </span>
                                    <span v-if="homeAstroData[0] && homeAstroData[0].moon_phase == 'Waning Gibbous'" class="has-text-right">
                                        <img src="../assets/lunar_icons/waning-gibbous.svg" alt="waning-gibbous" class="lunar">
                                    </span>
                                <p class="has-text-left">{{ $t("weather.home.mphase") }}: <span class="bolder" v-if="homeAstroData[0]">{{homeAstroData[0].moon_phase}}</span><span v-else>loading...</span></p>
                            </div>
                            <div class="block">
                                <!-- moon icon -->
                                <span v-if="homeAstroData[0]" class="has-text-right">
                                    <img src="../assets/sun_moon_icons/moon_illumination_3.svg" alt="moon-illumination" class="icn">
                                </span>
                                <p class="has-text-left">{{ $t("weather.home.millumination") }}: <span class="bolder" v-if="homeAstroData[0]">{{homeAstroData[0].moon_illumination}}</span><span v-else>loading...</span></p>                                
                            </div>
                        </div>
                        <div class="btm"></div>
                    </div>
                </b-tab-item>
                <!-- Lunar Calender -->
                <b-tab-item :label="`${$t('weather.heading.lunar')}`" icon="moon" icon-pack="fas" :visible="weatherFunctionality">
                    <div class="container is-max-desktop">
                        <!-- Today -->
                        <div class="text card lunar">
                            <header class="card-header">
                                <h4>{{ $t("weather.lunar.days.today") }} <span v-if="astroData[2]">{{astroData[2].date}}</span><span v-else>loading...</span></h4>
                            </header>
                            <!-- <h4>{{ $t("weather.lunar.date") }}: <span v-if="astroData[0]">{{astroData[0].date}}</span><span v-else>loading...</span></h4> -->
                            
                            <h4>{{ $t("weather.lunar.srise") }}: <span v-if="astroData[2]">{{astroData[2].sunrise}}</span><span v-else>loading...</span></h4>
                            <h4>{{ $t("weather.lunar.sset") }}: <span v-if="astroData[2]">{{astroData[2].sunset}}</span><span v-else>loading...</span></h4>
                            <h4>{{ $t("weather.lunar.mrise") }}: <span v-if="astroData[2]">{{astroData[2].moonrise}}</span><span v-else>loading...</span></h4>
                            <h4>{{ $t("weather.lunar.mset") }}: <span v-if="astroData[2]">{{astroData[2].moonset}}</span><span v-else>loading...</span></h4>
                            <h4>{{ $t("weather.lunar.mphase") }}: <span v-if="astroData[2]">{{astroData[2].moon_phase}}</span><span v-else>loading...</span>
                                <span v-if="astroData[2] && astroData[2].moon_phase == 'Full Moon'">
                                    <img src="../assets/lunar_icons/full-moon.svg" alt="full-moon" class="lunar">
                                </span>
                                <span v-if="astroData[2] && astroData[2].moon_phase == 'Waxing Gibbous'">
                                    <img src="../assets/lunar_icons/waxing-gibbous.svg" alt="waxing gibbous" class="lunar">
                                </span>
                                <span v-if="astroData[2] && astroData[2].moon_phase == 'First Quarter'">
                                    <img src="../assets/lunar_icons/first-quarter.svg" alt="first-quater" class="lunar-quarter">
                                </span>
                                <span v-if="astroData[2] && astroData[2].moon_phase == 'Waxing Crescent'">
                                    <img src="../assets/lunar_icons/waxing-crescent.svg" alt="waxing-crescent" class="lunar">
                                </span>
                                <span v-if="astroData[2] && astroData[2].moon_phase == 'New Moon'">
                                    <img src="../assets/lunar_icons/new-moon.svg" alt="new-moon" class="lunar">
                                </span>
                                <span v-if="astroData[2] && astroData[2].moon_phase == 'Waning Crescent'">
                                    <img src="../assets/lunar_icons/waning-crescent.svg" alt="waning-crescent" class="lunar">
                                </span>
                                <span v-if="astroData[2] && astroData[2].moon_phase == 'Third Quarter'">
                                    <img src="../assets/lunar_icons/third-quarter.svg" alt="third-quarter" class="lunar-quarter">
                                </span>
                                <span v-if="astroData[2] && astroData[2].moon_phase == 'Waning Gibbous'">
                                    <img src="../assets/lunar_icons/waning-gibbous.svg" alt="waning-gibbous" class="lunar">
                                </span>
                            </h4>
                            <h4>{{ $t("weather.lunar.millumination") }}: <span v-if="astroData[2]">{{astroData[2].moon_illumination}}</span><span v-else>loading...</span></h4>
                        </div>
                        <!-- Tomorrow -->
                        <div class="text card lunar">
                            <header class="card-header">
                                <h4>{{ $t("weather.lunar.days.tomorrow") }} <span v-if="astroData[0]">{{astroData[0].date}}</span><span v-else>loading...</span></h4>
                            </header>
                            <!-- <h4>{{ $t("weather.lunar.date") }}: <span v-if="astroData[1]">{{astroData[1].date}}</span><span v-else>loading...</span></h4> -->
                            <!-- MIT ICON??? 
                            <div class="block">
                                <div v-if="homeAstroData[0]"  class="has-text-right">
                                    <img src="../assets/sun_moon_icons/sun_rise_icon.svg" alt="sun-rise" class="icn">
                                </div>
                                <p class="has-text-left">{{ $t("weather.lunar.srise") }}: <span v-if="astroData[0]">{{astroData[0].sunrise}}</span><span v-else>loading...</span></p>
                            </div> -->
                            <h4>{{ $t("weather.lunar.srise") }}: <span v-if="astroData[0]">{{astroData[0].sunrise}}</span><span v-else>loading...</span></h4>
                            <h4>{{ $t("weather.lunar.srise") }}: <span v-if="astroData[0]">{{astroData[0].sunrise}}</span><span v-else>loading...</span></h4>
                            <h4>{{ $t("weather.lunar.sset") }}: <span v-if="astroData[0]">{{astroData[0].sunset}}</span><span v-else>loading...</span></h4>
                            <h4>{{ $t("weather.lunar.mrise") }}: <span v-if="astroData[0]">{{astroData[0].moonrise}}</span><span v-else>loading...</span></h4>
                            <h4>{{ $t("weather.lunar.mset") }}: <span v-if="astroData[0]">{{astroData[0].moonset}}</span><span v-else>loading...</span></h4>
                            <h4>{{ $t("weather.lunar.mphase") }}: <span v-if="astroData[0]">{{astroData[0].moon_phase}}</span><span v-else>loading...</span>
                                <span v-if="astroData[0] && astroData[0].moon_phase == 'Full Moon'">
                                    <img src="../assets/lunar_icons/full-moon.svg" alt="full-moon" class="lunar">
                                </span>
                                <span v-if="astroData[0] && astroData[0].moon_phase == 'Waxing Gibbous'">
                                    <img src="../assets/lunar_icons/waxing-gibbous.svg" alt="waxing gibbous" class="lunar">
                                </span>
                                <span v-if="astroData[0] && astroData[0].moon_phase == 'First Quarter'">
                                    <img src="../assets/lunar_icons/first-quarter.svg" alt="first-quater" class="lunar-quarter">
                                </span>
                                <span v-if="astroData[0] && astroData[0].moon_phase == 'Waxing Crescent'">
                                    <img src="../assets/lunar_icons/waxing-crescent.svg" alt="waxing-crescent" class="lunar">
                                </span>
                                <span v-if="astroData[0] && astroData[0].moon_phase == 'New Moon'">
                                    <img src="../assets/lunar_icons/new-moon.svg" alt="new-moon" class="lunar">
                                </span>
                                <span v-if="astroData[0] && astroData[0].moon_phase == 'Waning Crescent'">
                                    <img src="../assets/lunar_icons/waning-crescent.svg" alt="waning-crescent" class="lunar">
                                </span>
                                <span v-if="astroData[0] && astroData[0].moon_phase == 'Third Quarter'">
                                    <img src="../assets/lunar_icons/third-quarter.svg" alt="third-quarter" class="lunar-quarter">
                                </span>
                                <span v-if="astroData[0] && astroData[0].moon_phase == 'Waning Gibbous'">
                                    <img src="../assets/lunar_icons/waning-gibbous.svg" alt="waning-gibbous" class="lunar">
                                </span>
                            </h4>
                            <h4>{{ $t("weather.lunar.millumination") }}: <span v-if="astroData[0]">{{astroData[0].moon_illumination}}</span><span v-else>loading...</span></h4>
                        </div>
                        <!-- Day after tomorrow -->
                        <div class="text card lunar">
                            <header class="card-header">
                                <h4>{{ $t("weather.lunar.days.at") }} <span v-if="astroData[1]">{{astroData[1].date}}</span><span v-else>loading...</span></h4>
                            </header>
                            <!-- <h4>{{ $t("weather.lunar.date") }}: <span v-if="astroData[2]">{{astroData[2].date}}</span><span v-else>loading...</span></h4> -->
                            <h4>{{ $t("weather.lunar.srise") }}: <span v-if="astroData[1]">{{astroData[1].sunrise}}</span><span v-else>loading...</span></h4>
                            <h4>{{ $t("weather.lunar.sset") }}: <span v-if="astroData[1]">{{astroData[1].sunset}}</span><span v-else>loading...</span></h4>
                            <h4>{{ $t("weather.lunar.mrise") }}: <span v-if="astroData[1]">{{astroData[1].moonrise}}</span><span v-else>loading...</span></h4>
                            <h4>{{ $t("weather.lunar.mset") }}: <span v-if="astroData[1]">{{astroData[1].moonset}}</span><span v-else>loading...</span></h4>
                            <h4>{{ $t("weather.lunar.mphase") }}: <span v-if="astroData[1]">{{astroData[1].moon_phase}}</span><span v-else>loading...</span>
                                <span v-if="astroData[1] && astroData[1].moon_phase == 'Full Moon'">
                                    <img src="../assets/lunar_icons/full-moon.svg" alt="full-moon" class="lunar">
                                </span>
                                <span v-if="astroData[1] && astroData[1].moon_phase == 'Waxing Gibbous'">
                                    <img src="../assets/lunar_icons/waxing-gibbous.svg" alt="waxing gibbous" class="lunar">
                                </span>
                                <span v-if="astroData[1] && astroData[1].moon_phase == 'First Quarter'">
                                    <img src="../assets/lunar_icons/first-quarter.svg" alt="first-quater" class="lunar-quarter">
                                </span>
                                <span v-if="astroData[1] && astroData[1].moon_phase == 'Waxing Crescent'">
                                    <img src="../assets/lunar_icons/waxing-crescent.svg" alt="waxing-crescent" class="lunar">
                                </span>
                                <span v-if="astroData[1] && astroData[1].moon_phase == 'New Moon'">
                                    <img src="../assets/lunar_icons/new-moon.svg" alt="new-moon" class="lunar">
                                </span>
                                <span v-if="astroData[1] && astroData[1].moon_phase == 'Waning Crescent'">
                                    <img src="../assets/lunar_icons/waning-crescent.svg" alt="waning-crescent" class="lunar">
                                </span>
                                <span v-if="astroData[1] && astroData[1].moon_phase == 'Third Quarter'">
                                    <img src="../assets/lunar_icons/third-quarter.svg" alt="third-quarter" class="lunar-quarter">
                                </span>
                                <span v-if="astroData[1] && astroData[1].moon_phase == 'Waning Gibbous'">
                                    <img src="../assets/lunar_icons/waning-gibbous.svg" alt="waning-gibbous" class="lunar">
                                </span>
                            </h4>
                            <h4>{{ $t("weather.lunar.millumination") }}: <span v-if="astroData[1]">{{astroData[1].moon_illumination}}</span><span v-else>loading...</span></h4>
                        </div>
                    </div>
                </b-tab-item>
            </b-tabs>
      </div>
  </div>
</template>

<script>
import NavComp from "@/components/NavComp.vue"

export default {
    name: "Weather",
    components: {
        NavComp
    },
    data(){
        return{
            geobutton_rounded: true,
            geobutton_outline: false,
            geobutton_size: "default",
            geobutton_type: "is-success",
            geobutton_leftlabel: true,
            geobutton_passivetype: "is-danger",
            disableWatchPermission: false,
            
            //turn off weather func
            buttonloading: "",

            //Position Parameters
            currentPosition: "Loading...",
            lat: 0,
            lon: 0,
            accuracy:0, 
            altitude: 0,
            altitudeAccuracy: 0,
            error: "",
            options: {
                enableHighAccuracy: false,
                maximumAge: 5*60000, //maximum of 5 minutes to return a position
                timeput: 30*60000, //the cached location can be 30*60sec old
            },
            //id fo watch permission
            watchId: null,
            //weatherData
            weatherData: {},
            forecastData: {},
            forecastHour: {},
            //Homeposition
            homeLocation: {
                lat: 0,
                lon: 0,
            },
            homeWeatherData: {},
            homeForecastData: {},
            homeForecastHour: {},
            
            //Lunar Parameters
            astroData: {},
            homeAstroData: {},
        }
    },
    methods: {
        //Geolocation Functions
        async getPosition(position) {
            this.lat = position.coords.latitude.toFixed(2);
            this.lon = position.coords.longitude.toFixed(2);
            //save information in store
            if(this.lat && this.lat != 0 && this.lon && this.lon!=0){
                this.$store.dispatch("geolocation/change_geo_position", {lat: position.coords.latitude.toFixed(2), lon: position.coords.longitude.toFixed(2)});
            }
            //bonus information
            this.accuracy = position.coords.position;
            this.altitude = position.coords.altitude;
            this.altitudeAccuracy = position.coords.altitudeAccuracy;
            this.currentPosition = "Latitude: " + position.coords.latitude.toFixed(2)+" Longitude: " + position.coords.longitude.toFixed(2);

            //fetching the weatherData
            await this.fetchWeather(this.lat, this.lon);
            this.homeWeatherData = await this.getHomeLocationWeather(this.homeLocation.lat, this.homeLocation.lon);

            //fetching astro data
            this.astroData = await this.fetchLunarData(this.lat, this.lon);
        },
        getError(err) {
            switch(err.code){
                case err.PERMISSION_DENIED:
                    this.error = "User denied the request for Geolocation."
                    break;
                case err.POSITION_UNAVAILABLE:
                    this.error = "Location information is unavilable."
                    break;
                case err.TIMEOUT:
                    this.error = "The request to get user location timed out."
                    break;
                case err.UNKNOWN_ERROR:
                    this.error = "An unkonwn error occured.";
                    break;
            }
        },
        watchPositionError(err){
            this.error = 'Error with permanent watch! ERROR(' + err.code + '): ' + err.message;
        },
        //fetching weather Data from the SquareGarden Proxy API
        async fetchWeather(lat, lon) {
            //fetching with type and coordiantes (lat, lon)
            console.log("fetching data...") //https://squaregarden.sharky.live
            let data = await fetch(`https://squaregarden.sharky.live/api/v1/weather?type=all&lat=${lat}&lon=${lon}`);
            const text = await data.text();
            const json = await JSON.parse(text);
            this.weatherData = json.current.data;
            this.forecastData = json.forecast.general;
            this.forecastHour = json.forecast.hour;
        },
        //saving the current location data as homelocation
        async saveHomelocation() {
            if(this.lon && this.lat) this.$store.dispatch("geolocation/change_home_position", {lat: this.lat, lon: this.lon});
            //Getting the current values from the store
            this.homeLocation.lat = this.$store.getters["geolocation/getHomePosition"].lat;
            this.homeLocation.lon = this.$store.getters["geolocation/getHomePosition"].lon;
            const res = await this.getHomeLocationWeather(this.homeLocation.lat, this.homeLocation.lon);
            this.homeWeatherData = res.current.data;
            this.homeForecastData = res.forecast.general;
            this.homeForecastHour = res.forecast.hour;
        },
        async getHomeLocationWeather(lat, lon) {
            console.log("fetching data...") //https://squaregarden.sharky.live
            let data = await fetch(`https://squaregarden.sharky.live/api/v1/weather?type=all&lat=${lat}&lon=${lon}`);
            const text = await data.text();
            const json = await JSON.parse(text);
            return json;
        },
        toggleWeatherFunc() {
            this.$store.dispatch("geolocation/change_weather_functionality")
            this.buttonloading ="is-loading"
            setTimeout(()=> {
                this.buttonloading =""
            }, 750);
        },
        //Astro Data
        /**
         * @return json astro data
        */
        async fetchLunarData(lat, lon) {
            let data = await fetch(`https://squaregarden.sharky.live/api/v1/weather/astro?lat=${lat}&lon=${lon}`);
            if(data.status === 200) {
                const text = await data.text();
                const json = await JSON.parse(text);
                console.log(json)
                return json;
            }
        }
    },
    async beforeMount() {
        //ask for permission of geolocation
        if (navigator.geolocation) {
            //if the site is loaded and watch permission is activated
            if(this.watchPermission) {
                if(this.watchPermission){
                    // watch geolocation the whole time
                    if(navigator.geolocation) { //which should be the case
                        this.watchId = navigator.geolocation.watchPosition(await this.getPosition, this.watchPositionError, this.options);
                    } else {
                        console.log("no watch possible");
                    }
                }
            } else {
                //get just the current location
                navigator.geolocation.getCurrentPosition(await this.getPosition, this.getError, this.options);
            }
        } else {
            this.$data.error = "Geolocation is not supported.";
        }

        //Getting the current Homelocation
        this.homeLocation.lat = this.$store.getters["geolocation/getHomePosition"].lat;
        this.homeLocation.lon = this.$store.getters["geolocation/getHomePosition"].lon;

        //Getting current Astro data for homelocation
        if(this.homeLocation.lat != 0 && this.homeLocation.lon != 0){
            this.homeAstroData = await this.fetchLunarData(this.homeLocation.lat, this.homeLocation.lon)
        }
    },
    computed: {
        watchPermission: {
            get() { return this.$store.getters["geolocation/getWatchStatus"]; },
            set() { 
                this.$store.dispatch("geolocation/change_watch_status");
                //disable the button for a second
                this.disableWatchPermission = true; 
                setTimeout(()=> {
                    this.disableWatchPermission = false;
                }, 500);
                }
        },
        //is weather functionality enabled
        weatherFunctionality: {
            get() { return this.$store.getters["geolocation/getWeatherFuncStatus"]; },
            set() {
                this.$store.dispatch("geolocation/change_weather_functionality")
                this.disableWatchPermission ="is-loading"
                setTimeout(()=> {
                    this.disableWatchPermission =""
                }, 500);
            }
        },
    },
    watch: {
      watchPermission: function() {
        if(this.watchPermission){
            // watch geolocation the whole time
            if(navigator.geolocation) { //which should be the case
                this.watchId = navigator.geolocation.watchPosition(this.getPosition, this.watchPositionError, this.options);
            } else {
                console.log("no watch possible");
                //wenn gerade schon gewatched wird dann schließe das weil offensichtlich ein Fehler aufgetreten ist
                if(this.matchId){
                    navigator.geolocation.clearWatch(this.watchId);
                }
            }
        } else {
            //save last location 
            navigator.geolocation.getCurrentPosition(this.getPosition, this.getError, this.options)
            //and end geo watching
            navigator.geolocation.clearWatch(this.watchId);
        }
      }
    }
}
</script>

<style>

.block {
    display: grid;
    grid-template-columns: 2fr 3fr;
}

.mid {
    margin: 0 auto;
}

h4 {
    margin: 10px;
    font-weight: 500;
    font-size: 1.2rem;
}

h5 {
    margin: 5px;
    font-weight: 500;
    font-size: 1.1rem;
}

h6 {
    margin-left: 10px;
    font-weight: 430;
    font-size: 1.1rem;
}

p {
    font-weight: 400;
    font-size: 1rem;
}

.bolder {
    font-weight: 500;
}

.half-card {
    max-width: 700px;
}

.mt-8 {
    margin-top: 8rem;
}

img.lunar {
    width: 35px;
    height: 35px;
    margin: 0 30px;
}

img.icn {
    width: 50px;
    height: 50px;
    margin: 0 15px;
}

img.lunar-quarter {
    width: 45px;
    height: 45px;
}

.text {
    margin-left: 3em;
}

.card {
    margin-bottom: 10px;
}

.lunar {
    max-width: 400px;
    margin: 10px auto;
}

.btm {
    margin-bottom: 15px;
}
</style>